
const words = {
  Status: 'Status',
  Company: 'Empresa',
  Companies: 'Empresas',
  Value: 'Valor',
  Date: 'Data',
  Transaction: 'Transação',
  Transactions: 'Transações',
  Today: 'Hoje',
  Prev: 'Anterior',
  Next: 'Próximo',
  Waiting: 'Aguardando',
  Processing: 'Processando',
  Canceled: 'Cancelado',
  CanceledPlural: 'Cancelados',
  Expired: 'Expirado',
  ExpiredPlural: 'Expirados',
  Approved: 'Aprovado',
  ApprovedPlural: 'Aprovados',
  Inconclusive: 'Inconclusivo',
  Inconclusives: 'Inconclusivos',
  Refused: 'Recusado',
  RefusedPlural: 'Recusados',
  Shared: 'Compartilhado',
  SharedPlural: 'Compartilhados',
  Apply: 'Aplicar',
  Cancel: 'Cancelar',
  Report: 'Relatório',
  Reports: 'Relatórios',
  Panel: 'Painel',
  Settings: 'Configurações',
  Logout: 'Logout',
  final: 'final',
  Hello: 'Olá',
  Information: 'Informação',
  Back: 'Voltar',
  Confirm: 'Confirmar',
  Continue: 'Continuar',
  seconds: 'segundos',
  Select: 'Selecione',
}

const errors = {
  "MSG_ERROR_40024": "O nome informado não é válido",
  "MSG_ERROR_40021": "O telefone informado não é válido",
  "MSG_ERROR_40022": "O email informado não é válido",
  "MSG_ERROR_40027": "Já foi enviada uma transação com esse número do pedido",
  "MSG_ERROR_40045": "O valor da transação ultrapassa os limites aceitos",
  "MSG_ERROR_40012": "Não conseguimos autenticar a foto. Tente novamente.",
  "MSG_ERROR_DEFAULT": "Ops! Algo Inesperado aconteceu. Tente novamente dentro de alguns segundos.",
  "MSG_ERROR_CREATE_TRANSACTION": "Não foi possível criar a transação.",
}

const msg_report = {
  "MSG_ERROR_REPORT_204": "Não existem transações com os filtros selecionados.",
  "MSG_ERROR_REPORT_40035": "A quantidade de transações excede o limite máximo. Por favor, selecione um período menor.",
  "MSG_ERROR_REPORT_DEFAULT": "Não foi possível gerar o relatório agora. Tente novamente mais tarde.",
  "MSG_SUCCESS_REPORT": "Recebemos o pedido de geração de documento: o relatório será enviado para o seu e-mail.",
};

const buttons = {
  owner: 'Sou o titular',
}

export default {
  translations: {
    ...words,
    ...errors,
    ...msg_report,
    ...buttons,
    'BodyEvidence': 'Conjunto Probatório',
    'chooseCompany': 'Escolha a empresa',
    'footer.privacyPolicy': 'Política de privacidade',
    'footer.allRightsReserved': 'Todos os direitos reservados',
    'footer.lastUpdate': 'Última atualização em',
    'filter.selectPeriod': 'Selecionar período',
    'button.newAutentication': 'Nova autenticação',
    'search.placeholder': 'N° do pedido ou CPF',
    'transactions.title': words.Transactions,
    'transactions.dateDays': '{{days}} dias',
    'transactions.noPermission': 'Usuário sem permissão de ver as transações de',
    'transactions.columns.orderNumber': 'N° pedido',
    'transactions.columns.company': words.Company,
    'transactions.columns.companyKind': 'Tipo de uso',
    'transactions.columns.identificationDoc': 'CPF',
    'transactions.columns.value': words.Value,
    'transactions.columns.cardNumber': 'Cartão de Crédito',
    'transactions.columns.date': words.Date,
    'transactions.columns.status': words.Status,
    'transaction.status.processHeader.filterLabel': 'Status do <strong>processo</strong>',
    'transaction.status.responseHeader.filterLabel': 'Status de <strong>resposta</strong>',
    'transaction.status.userActionsHeader.filterLabel': 'Status de <strong>ações do usuário</strong>',
    'transaction.status.approved.label': words.Approved,
    'transaction.status.approved.filterLabel': words.ApprovedPlural,
    'transaction.status.canceled.label': words.Canceled,
    'transaction.status.canceled.filterLabel': words.CanceledPlural,
    'transaction.status.absentHolder.label': 'Titular ausente',
    'transaction.status.absentHolder.filterLabel': 'Titular ausente',
    'transaction.status.disapproved.label': words.Refused,
    'transaction.status.disapproved.filterLabel': words.RefusedPlural,
    'transaction.status.inconclusive.label': words.Inconclusive,
    'transaction.status.inconclusive.filterLabel': words.Inconclusives,
    'transaction.status.fastInconclusive.label': 'Inconclusivo rápido',
    'transaction.status.fastInconclusive.filterLabel': 'Inconclusivos rápido',
    'transaction.status.processing': words.Processing,
    'transaction.status.shared.label': words.Shared,
    'transaction.status.shared.filterLabel': words.SharedPlural,
    'transaction.status.waiting': words.Waiting,
    'transaction.status.skiped': 'Pulou biometria',
    'transaction.status.unknownShare.label': 'Não reconhece',
    'transaction.status.unknownShare.filterLabel': 'Não reconhece compra',
    'transaction.status.expired.label': words.Expired,
    'transaction.status.expired.filterLabel': words.ExpiredPlural,
    'transaction.chargeback.none': 'Sem pedido',
    'transaction.chargeback.analyzing.label': 'Em análise',
    'transaction.chargeback.analyzing.detailLabel': 'Análise em andamento...',
    'transaction.chargeback.waiting.label': 'Aguardando análise',
    'transaction.chargeback.waiting.detailLabel': 'Aguardando análise...',
    'transaction.chargeback.refused.label': 'Chargeback negado',
    'transaction.chargeback.refused.detailLabel': 'Negado',
    'transaction.chargeback.approved.label': 'Chargeback aprovado',
    'transaction.chargeback.approved.detailLabel': words.Approved,

    /* Report */
    'report.title': words.Reports,
    'report.filters.title': 'Selecione os filtros e períodos desejados para exportar o relatório',
    'report.filters.companies': words.Companies,
    'report.filters.status': words.Status,
    'report.filters.chargeback': 'Chargeback',
    'report.filters.obs': 'Obs.: O relatório será enviado para o seu email',
    'report.filters.button.generate': 'Gerar o documento ',

    /* Dashboard */
    'dashboard.warning': 'Algumas transações ainda estão em processamento, por isso os números sofrerão alterações ao longo do dia',
    'dashboard.transactions.approved': 'Transações aprovadas',
    'dashboard.transactions.eligible': 'Transações elegíveis',
    'dashboard.transactions.received': 'Transações recebidas',
    'dashboard.transactions.viewAll': 'Ver todas transações',
    'dashboard.value.approved': 'Valor aprovado',
    'dashboard.value.received': 'Valor recebido',
    'dashboard.percentage.opened': 'Taxa de abertura',
    'dashboard.percentage.captured': 'Taxa de captura',
    'dashboard.approval.resume': 'Aprovação total',
    'dashboard.warning.maxFilterByDate': 'Não é possível filtrar um período maior que {{max}} dias',

    /* Settings */
    'settings.add': 'Adicionar',
    'settings.allowedDomains': 'Domínios liberados',
    'settings.maxAllowedDomainsExceeded': 'Número máximo de domínios',
    'settings.domainText': 'Texto do domínio',
    'settings.deleteDomain': 'Excluir domínio',

    /* Issuer Portal */
    'issuer.transactions.columns.company': 'Estabelecimento',
    'issuer.transactions.columns.date': 'Data da transação',
    'issuer.transactions.columns.consult': 'Consulta',

    /* Card Consult */
    'card-consult.approved': 'Válido',
    'card-consult.unsure': 'Inválido',
    'card-consult.waiting': 'Aguardando',

    /* Modal Notify Chargeback */
    'transaction.modal.notifyChargeback.filesRequired': 'A comprovação do chargeback é obrigatória',
    'transaction.modal.notifyChargeback.fail': 'Não foi possível enviar a contestação',
    'transaction.modal.notifyChargeback.success': 'Contestação enviada',
    'transaction.modal.notifyChargeback.cancel': words.Cancel,
    'transaction.modal.notifyChargeback.confirm': words.Confirm,
    'transaction.modal.notifyChargeback.title': 'Nova contestação',

    'transaction.modal.notifyChargeback.form.chargebackDate': 'Data da contestação*',
    'transaction.modal.notifyChargeback.form.document': 'CPF da pessoa que contestou*',
    'transaction.modal.notifyChargeback.form.name': 'Nome da pessoa que contestou*',
    'transaction.modal.notifyChargeback.form.reason': 'Motivo da contestação*',
    'transaction.modal.notifyChargeback.form.obs': 'Observações',
    'transaction.modal.notifyChargeback.form.attachEvidences': 'Anexar evidências*',
    'transaction.modal.notifyChargeback.form.attachEvidencesObs': 'Obs.: a comprovação do chargeback é obrigatória',
    'transaction.modal.notifyChargeback.form.file': 'Anexar evidências*',

    'transaction.modal.notifyChargeback.upload.attachEvidencesObs': 'Obs.: a comprovação do chargeback é obrigatória',
    'transaction.modal.notifyChargeback.upload.label': 'Anexe aqui o documento',
    'transaction.modal.notifyChargeback.upload.failLabel': 'Não foi possível enviar o arquivo de contestação',
    'transaction.modal.notifyChargeback.upload.successLabel': 'Arquivo de contestação enviados com sucesso',
    'transaction.modal.notifyChargeback.upload.ruleMaxFilesMessage': 'A quantidade máxima de evidências foi atingida',

    /* Manual Workflow Screens */
    'sms.footer.textPrivacyPolicy': 'Caso deseje saber mais sobre como tratamos e porque coletamos seus dados pessoais, confira nossa ',
    'sms.footer.termPrivacyPolicy': 'Política de Privacidade.',
    'sms.footer.altImgSafe': 'Ambiente seguro',
    'sms.orderSummary.company': 'Local da compra',
    'sms.orderSummary.value': 'Valor total da compra',
    'sms.orderSummary.creditCard': 'Cartão de crédito',
    'sms.orderSummary.captureDate': 'Quando a selfie foi tirada',
    'sms.orderSummary.title': 'Dados da compra',
    'sms.registerSummary.title': 'Dados do cadastro',
    'sms.registerSummary.company': 'Local do cadastro',
    'sms.registerSummary.creditCard': 'Cartão cadastrado',
    'sms.tips.title': 'Dicas de captura',
    'sms.tips.cameraAccess': 'Permita o acesso à câmera',
    'sms.tips.areaLit': 'Encontre uma área bem iluminada',
    'sms.tips.phoneEye': 'Segure o telefone no nível dos olhos',
    'sms.tips.removeGlasses': 'Retire óculos, máscara, chapéu e fones',
    'sms.notify.presentation.title.simplified': '<strong>{{name}}</strong>, para validar sua compra, precisamos de uma selfie do titular do cartão com o <strong>final {{lastDigits}}</strong>',
    'sms.notify.presentation.title.contextful': 'Olá, <strong>{{name}}</strong><br />Precisamos de uma selfie do titular do cartão de crédito.',
    'sms.notify.presentation.subtitle.simplified': 'Caso não seja você, peça para o dono tirar a foto',
    'sms.notify.presentation.subtitle.contextful': 'Queremos garantir se essa compra é verdadeira e reconhecida por você.',
    'sms.notify.presentation.buttonMobile.owner.simplified': 'Sou o titular, tirar selfie',
    'sms.notify.presentation.buttonMobile.owner.contextful': buttons.owner,
    'sms.notify.presentation.button.share.simplified': 'Pedir selfie para o titular do cartão',
    'sms.photoAlreadyTaken': 'Já tirei a foto',
    'sms.buttonShare': 'Enviar para o titular',
    'sms.buttonCopyLink': 'Copiar link',
    'sms.notify.presentation.button.educationSelfie': 'Entenda por que precisamos da selfie',
    'sms.modalSelfieExplain.title': 'Por que tirar a selfie?',
    'sms.modalSelfieExplain.text1': 'Precisamos da <strong>confirmação da compra</strong> pelo <strong>titular</strong> do cartão utilizado.',
    'sms.modalSelfieExplain.text2': 'Essa validação serve tanto para proteger o titular do cartão quanto garantir a realização da compra.',
    'sms.modalSelfieExplain.confirmButton': 'Ok, entendi',
    'sms.modalSDKError.title': 'Ops! Algo deu errado.',
    'sms.modalCopyLink.mobileTitle': 'Mobile - Autenticação',
    'sms.modalCopyLink.title': 'Link copiado',
    'sms.modalCopyLink.text': 'O link foi copiado e pode ser compartilhado através do WhatsApp',
    'sms.notify.confirmOwnerData.title': 'Confirme o CPF do titular do cartão',
    'sms.notify.confirmOwnerData.button.CPFExplain': 'Entenda por que precisamos do CPF',
    'sms.notify.confirmOwnerData.labelCPF': 'Número do CPF',
    'sms.notify.confirmOwnerData.modalCPFExplain.title': 'Por que pedimos o CPF?',
    'sms.notify.confirmOwnerData.modalCPFExplain.text1': 'Nós precisamos autenticar a identidade do titular do cartão de crédito para garantir uma maior segurança nessa compra.',
    'sms.notify.confirmOwnerData.modalCPFExplain.text2': 'O titular <strong>é a pessoa dona do CPF</strong> que está associado ao <strong>nome impresso no cartão</strong>.',
    'sms.notify.confirmOwnerData.modalCPFExplain.confirmButton': 'Ok, entendi',
    'sms.notify.confirmOwnerData.relationship.title': 'Qual sua relação com o comprador?',
    'sms.notify.confirmOwnerData.relationship.subtitle': 'O titular do CPF: {{identity}} está realizando uma compra com o cartão de final {{lastDigits}}. Informe sua relação com essa pessoa.',
    'sms.notify.confirmOwnerData.relationship.label': 'Essa pessoa é meu / minha:',
    'sms.notify.confirmOwnerData.relationship.labelOther': 'Descreva sua relação:',
    'sms.notify.confirmOwnerData.relationship.placeholderOther': 'Escreva aqui',
    'sms.notify.confirmOwnerData.relationship.option.fatherMother': 'Pai ou mãe',
    'sms.notify.confirmOwnerData.relationship.option.brotherSister': 'Irmão ou Irmã',
    'sms.notify.confirmOwnerData.relationship.option.sonDaughter': 'Filho ou Filha',
    'sms.notify.confirmOwnerData.relationship.option.grandfatherGrandmother': 'Avô ou Avó',
    'sms.notify.confirmOwnerData.relationship.option.uncleAunt': 'Tio ou Tia',
    'sms.notify.confirmOwnerData.relationship.option.motherLawFatherLaw': 'Sogro ou Sogra',
    'sms.notify.confirmOwnerData.relationship.option.friend': 'Amigo(a)',
    'sms.notify.confirmOwnerData.relationship.option.spouse': 'Cônjuge',
    'sms.notify.confirmOwnerData.relationship.option.partner': 'Companheiro(a)',
    'sms.notify.confirmOwnerData.relationship.option.grandsonGranddaughter': 'Neto ou Neta',
    'sms.notify.confirmOwnerData.relationship.option.brotherLawSisterLaw': 'Cunhado ou Cunhada',
    'sms.notify.confirmOwnerData.relationship.option.nephewNiece': 'Sobrinho ou sobrinha',
    'sms.notify.confirmOwnerData.relationship.option.cousin': 'Primo ou Prima',
    'sms.notify.confirmOwnerData.relationship.option.professional': 'Profissional',
    'sms.notify.confirmOwnerData.relationship.option.sonLawDaughterLaw': 'Genro ou Nora',
    'sms.notify.confirmOwnerData.relationship.option.other': 'Outro',
    'sms.validateWaitMsg': 'Estamos analisando suas informações',
    'sms.waitMsg': 'Aguarde um momento',
    'sms.sharedSteps.alreadyShared.title': 'Você compartilhou o link com o titular do cartão',
    'sms.sharedSteps.alreadyShared.subtitle': 'A verificação está pendente até a foto ser capturada.',
    'sms.sharedSteps.alreadyShared.labelOwner': 'O titular está próximo a você?',
    'sms.sharedSteps.alreadyShared.buttonContinueCapture': 'Continuar captura',
    'sms.sharedSteps.congratulations.title': 'Selfie tirada com sucesso',
    'sms.sharedSteps.congratulations.subtitle': 'Agora é só aguardar a análise. Caso necessário, {{company}} entrará em contato.',
    'sms.sharedSteps.congratulationsReturnToDevice.title': 'Selfie tirada com sucesso',
    'sms.sharedSteps.congratulationsReturnToDevice.subtitle': 'Para finalizar, volte ao dispositivo inicial.',
    'sms.sharedSteps.error.title': 'Transação não existente',
    'sms.sharedSteps.error.subtitle': 'Para mais informações entre em contato com o estabelecimento da sua compra.',
    'sms.sharedSteps.expired.title': 'Esse link está expirado!',
    'sms.sharedSteps.expired.subtitle': 'Para mais informações entre em contato com o estabelecimento da sua compra.',
    'sms.sharedSteps.incorrectLink.title': 'Não encontramos a validação',
    'sms.sharedSteps.incorrectLink.subtitle': 'Confira se o link foi digitado corretamente.',
    'sms.sharedSteps.incorrectLink.warning': 'Em casos de compartilhamento, confira se o link foi copiado por completo.',
    'sms.sharedSteps.orderNotRecognized.title': 'Compra não reconhecida',
    'sms.sharedSteps.orderNotRecognized.subtitle': 'Estamos informando o estabelecimento sobre a compra indevida',
    'sms.sharedSteps.prepare.title': 'Prepare-se para tirar a selfie',
    'sms.sharedSteps.prepare.subtitle': 'A selfie deve ser do titular do CPF',
    'sms.sharedSteps.prepare.subtitleWithCPF': 'A selfie deve ser do titular do CPF: {{cpf}}',
    'sms.sharedSteps.prepare.subtitleWithInstruction': 'Vamos usar a foto para confirmar sua identidade. Por isso:',
    'sms.sharedSteps.prepare.msgWait': 'Estamos nos preparando para capturar sua selfie',
    'sms.sharedSteps.prepare.buttonCaptureSelfie': 'Permitir acesso à câmera',
    'sms.sharedSteps.prepare.buttonShare': 'Pedir selfie para o titular do cartão',
    'sms.sharedSteps.QRCode.title': 'Aponte a câmera do seu celular para o QRCode ou copie o link',
    'sms.sharedSteps.QRCode.subtitle': 'Após tirar a foto, <strong>volte para finalizar</strong> a validação nesta página',
    'sms.sharedSteps.QRCode.warning': 'Não feche esta página enquanto captura a foto',
    'sms.sharedSteps.QRCode.desktopError.title': 'Não conseguimos acessar sua câmera',
    'sms.sharedSteps.QRCode.desktopError.subtitle': 'Continue essa transação em outro dispositivo. Aponte a câmera do seu celular para o QR Code ou compartilhe o link clicando no botão abaixo.',
    'sms.sharedSteps.QRCode.buttonCopyLink': '<strong>Copie o link</strong> para tirar a foto em outro dispositivo',
    'sms.sharedSteps.sharingVerification.title': 'Você <strong>compartilhou</strong> o link com o titular do cartão',
    'sms.sharedSteps.sharingVerification.subtitle': 'A verificação está pendente até a foto ser tirada',
    'sms.sharedSteps.sharingVerification.selfieExplain': 'A selfie precisa ser da pessoa dona do cartão.',
    'sms.sharedSteps.sharingVerification.alreadyShared': 'Voltar para a loja',
    'sms.sharedSteps.sharingVerification.finishVerification': 'Finalizar verificação',
    'sms.sharedSteps.sharingVerification.owner': 'Seguir verificação por aqui',
    'sms.sharedSteps.sharingVerification.explain': 'Se você não for a pessoa dona do cartão, é só compartilhar o link com o titular',
    'sms.sharedSteps.sharingVerification.buttonShare': 'Compartilhar o link com o titular',
    'sms.sharedSteps.verificationFinished.title': 'Verificação finalizada',
    'sms.sharedSteps.verificationFinished.subtitle': 'A loja irá seguir com a verificação diretamente por lá',
    "sms.showErrorIcon": "Ícone de alerta",
    'sms.copyLink.title': 'Link copiado',
    'sms.copyLink.msg': 'O link foi copiado para que possa tirar a foto em outro dispositivo',
    "sms.share.auth.title": "Precisamos de uma selfie do titular do cartão de crédito",
    "sms.share.auth.subtitle": "Autorize a compra que está sendo feita através da foto.",
    "sms.share.auth.fieldName": "Nome escrito no cartão",
    "sms.share.auth.fieldCPF": "Número do CPF",
    "sms.validateCPF": "Digite o CPF completo",
    "sms.share.auth.buttonOwner": "Tirar selfie",
    'sms.button.photoWebcam': 'Tirar foto com webcam',
    'sms.button.photoMobile': 'Tirar foto com celular',
    'sms.button.noWebcam': 'Não tenho webcam',
    'sms.button.unknown': 'Não reconheço essa compra',
    'sms.button.information': 'Por que recebi esse link?',
    'sms.button.notOwner': 'Não sou o titular',
    'sms.button.ownerNotPresent': 'O titular não está presente',
    'sms.button.notOwner.label': 'Você é o titular do cartão utilizado?',
    'sms.modal.orderNotRecognized.title': 'Tem certeza que não reconhece essa compra?',
    'sms.modal.orderNotRecognized.text': 'Ao seguir, iremos avisar o estabelecimento sua confirmação.',
    'sms.modal.orderNotRecognized.confirmButton': 'Não reconheço a compra',
    'sms.modal.linkExplain.title': 'Por que recebi esse link?',
    'sms.modal.linkExplain.text.1': 'A pessoa que realizou uma compra com o cartão de <strong>final {{lastDigits}}</strong> te enviou o pedido de verificação através da confirmação de identidade por selfie.',
    'sms.modal.linkExplain.text.2': 'Essa validação da compra serve tanto para proteger você, o titular do cartão, quanto quem está realizando o pedido.',
    'sms.modal.linkExplain.text.3': 'Caso <strong>não reconheça essa compra</strong>, selecione a opção "Não reconheço compra" que informaremos ao estabelecimento.',
    'sms.modal.linkExplain.confirmButton': 'Ok, entendi',
    'sms.modal.notOwner.title': 'O titular está presente?',
    'sms.modal.notOwner.text': 'Se você estiver próximo à pessoa titular, por favor, passe o dispositivo para essa pessoa prosseguir com a verificação.',
    'sms.modal.notOwner.textNotPresent': 'Ao não tirar a selfie, cancelaremos a autorização do pagamento.',
    'sms.modal.notOwner.confirmButton': 'Continuar verificação',
    'sms.modal.notOwner.closeButton': 'Finalizar verificação',
    'sms.modal.selfieNotFound.title': 'Selfie não encontrada',
    'sms.modal.selfieNotFound.text': 'Finalize a captura no dispositivo móvel para seguir com a validação.',
    'sms.modal.selfieNotFound.confirmButton': 'Ok, entendi',
    'sms.alt.warningSymbol': 'Símbolo de aviso',
    'chooseLanguage': 'Escolha o idioma',
    'chooseLanguage.PT': 'Português',
    'chooseLanguage.EN': 'English',
    'chooseLanguage.ES': 'Español',
    'chooseLanguage.KK': 'қазақ',
    'sms.popUpPermissions.title': 'Para seguir, vamos te pedir a permissão de acesso abaixo',
    'sms.popUpPermissions.geolocalization.title': 'Localização',
    'sms.popUpPermissions.geolocalization.text': 'Usamos esse dado para aumentar a confiança na validação.',
    'sms.popUpPermissions.camera.title': 'Câmera',
    'sms.popUpPermissions.camera.text': 'Usamos essa permissão para capturar a foto do seu rosto.',
    'sms.popUpPermissions.continueButton': words.Continue,

    'sms.sharedSteps.sharingVerification.helpPermission.buttonTryAgain': 'Tentar novamente',
    'sms.sharedSteps.sharingVerification.helpPermission.titleInstructions': 'Instruções para <strong>permissão de câmera</strong>',
    'sms.sharedSteps.sharingVerification.helpPermission.titleWithBrowser': 'Saiba como permitir acesso à câmera no <br/> <icon/> <strong>{{name}}</strong>',
    'sms.sharedSteps.sharingVerification.helpPermission.titleWithoutBrowser': 'Saiba como permitir acesso à câmera no <strong>{{name}}</strong>',
    'sms.sharedSteps.sharingVerification.helpPermission.android': 'Android',
    'sms.sharedSteps.sharingVerification.helpPermission.ios': 'iOS',
    'sms.sharedSteps.sharingVerification.helpPermission.windows': 'Windows',
    'sms.sharedSteps.sharingVerification.helpPermission.macOS': 'macOS',
    'sms.sharedSteps.sharingVerification.helpPermission.titleNoPermission': 'Entenda como pode realizar essa permissão',
    'sms.sharedSteps.sharingVerification.helpPermission.subtitleNoPermission': 'Selecione o modelo utilizado e siga as instruções',
    'sms.sharedSteps.sharingVerification.helpPermission.haveOS': 'Tenho {{name}}',
    'sms.sharedSteps.sharingVerification.helpPermission.instructionPermission': 'Instruções de permissão',
    'sms.sharedSteps.sharingVerification.helpPermission.googleChrome': 'Google Chrome',
    'sms.sharedSteps.sharingVerification.helpPermission.samsunginternet': 'Samsung Internet',
    'sms.sharedSteps.sharingVerification.helpPermission.safari': 'Safari',
    'sms.sharedSteps.sharingVerification.helpPermission.mozillaFirefox': 'Mozilla Firefox',
    'sms.sharedSteps.sharingVerification.helpPermission.otherBrowser': 'Outro navegador',
    'sms.sharedSteps.sharingVerification.helpPermission.browser': 'Navegador',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidChrome.1': 'À direita da barra de endereço, toque no três pontos <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidChrome.2': 'Toque em Configurações <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidChrome.3': 'Desça a tela e selecione a opção Configurações do site',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidChrome.4': 'Toque em Câmera e ative a permissão <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidChrome.5': 'Caso o link <strong>https://idpay.unico.io</strong> esteja bloqueado, selecione-o e aperte em Permitir',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidSamsungBrowser.1': 'À direita da barra inferior, selecione os três traços <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidSamsungBrowser.2': 'Selecione a opção Configurações <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidSamsungBrowser.3': 'Desça a página e selecione a opção Sites e downloads',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidSamsungBrowser.4': 'Toque em Permissões de site e ligue a Câmera <icon />',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidGenericBrowse.1': 'Abra o aplicativo Configurações <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidGenericBrowse.2': 'Desça a tela e selecione Aplicativos',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidGenericBrowse.3': 'Procure pelo nome do navegador que está utilizando',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidGenericBrowse.4': 'Toque em Permissões e selecione Câmera <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileAndroidGenericBrowse.5': 'Selecione a opção Permitir durante o uso do app e volte para salvar sua edição',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosChrome.1': 'Abra o aplicativo Ajustes',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosChrome.2': 'Desça a tela e toque em Chrome',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosChrome.3': 'Deixe a linha Câmera ativa <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosChrome.4': 'Recomece a captura e aceite o novo pedido de permissão',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosSafari.1': 'Abra o aplicativo Ajustes',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosSafari.2': 'Desça a tela e toque em Safari',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosSafari.3': 'Ao final da página, selecione Câmera e toque em permitir',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosGenericBrowser.1': 'Abra o aplicativo Ajustes',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosGenericBrowser.2': 'Desça a tela e toque no nome do navegador que está utilizando',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosGenericBrowser.3': 'Selecione a opção Câmera e toque em permitir <icon1 /> ou ativar <icon2 />',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.mobileIosGenericBrowser.4': 'Recomece a captura e aceite o novo pedido de permissão',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsChrome.1': 'Clique no erro de permissão ou no <strong>ícone de cadeado</strong> <icon/> à esquerda da barra de endereço.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsChrome.2': 'Clique em <strong>Configurações do site</strong> <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsChrome.3': 'Clique em <strong>Privacidade e Segurança</strong> <icon/> e depois em <strong>Câmera</strong>, selecione <strong>”Permitir”</strong>.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsMozilla.1': 'Clique no ícone de menu no canto superior direito da janela e selecione "Opções".',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsMozilla.2': 'Na barra lateral esquerda, clique em "Privacidade e Segurança".',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsMozilla.3': 'Desça até a seção "Permissões" e encontre a opção "Câmera".',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsMozilla.4': 'Deixe a opção ”Pedir para Ativar a Câmera” marcada.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsMozilla.5': 'Caso o link desta página esteja bloqueado, clique em ”Exceções” e adicione para continua',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsGenericBrowser.1': 'Clique no erro de permissão ou no <strong>ícone de cadeado</strong> <icon/> à esquerda da barra de endereço.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsGenericBrowser.2': 'Clique em <strong>Configurações do site</strong> <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopWindowsGenericBrowser.3': 'Clique em <strong>Privacidade e Segurança</strong> <icon/> e depois em <strong>Câmera</strong>, selecione <strong>”Permitir”</strong>.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopMac.1': 'Clique no erro de permissão ou no <strong>ícone de cadeado</strong> <icon/> à esquerda da barra de endereço.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopMac.2': 'Ative a <strong>Câmera</strong> com o seletor <icon/> ou clique em <strong>“continuar permitindo acesso à sua câmera”</strong>.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopMac.3': 'Se a câmera está desligada nas configurações do Mac, clique em <strong>Abrir Preferências</strong>, ou acesse as <strong>Ajustes</strong> <icon/> do seu computador.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopMac.4': 'Acesse <icon/> <strong>Câmera</strong>.',
    'sms.sharedSteps.sharingVerification.helpPermission.tips.desktopMac.5': 'Localize o navegador que você utiliza e habilite o seletor <icon/>',
    'sms.sharedSteps.sharingVerification.helpPermission.pageTitle': 'Você não permitiu o acesso à câmera',
    'sms.sharedSteps.sharingVerification.helpPermission.pageSubtitle': 'Sem o acesso, não podemos confirmar sua identidade e validar a compra.',
    'sms.sharedSteps.sharingVerification.helpPermission.buttonNotWorked': 'Ainda não funcionou?',
    'sms.sharedSteps.sharingVerification.helpPhotoQuality.titleTipPhoto': 'Mantenha seu rosto no centro da forma oval e siga as instruções abaixo:',
    'sms.sharedSteps.sharingVerification.helpPhotoQuality.pageTitle': 'Não conseguimos validar sua identidade',
    'sms.sharedSteps.sharingVerification.helpPhotoQuality.pageSubtitle': 'A qualidade da foto pode ter interferido na verificação. Por favor, tente novamente.',
    'sms.sharedSteps.sharingVerification.helpPhotoQuality.tip1': 'Dicas para aumentar a qualidade da captura',
    'sms.sharedSteps.sharingVerification.helpPhotoQuality.tip2': 'Segure seu telefone na altura dos olhos.',
    'sms.sharedSteps.sharingVerification.helpPhotoQuality.tip3': 'Encontre um lugar bem iluminado e retire acessórios como chapéu, óculos, etc.',

    'sms.component.timer': 'Você tem <time/> para tirar a foto',

    // Frictionless flow
    'sms.component.summary.timer': 'A verificação irá começar em',
    'sms.notify.presentationFrictionless.title': 'Vamos fazer a verificação de segurança da compra abaixo',
    'sms.notify.presentationFrictionless.buttonContinueVerification': 'Começar a verificação',
    'sms.notify.congratulationsFrictionless.title': 'Compra reconhecida',
    'sms.notify.congratulationsFrictionless.subtitle': 'Agora é só aguardar o retorno da loja sobre o status deste pedido.',
    'sms.sharedSteps.prepare.frictionless.title': 'Para finalizar, tire uma foto do titular do cartão utilizado na compra',
    'sms.sharedSteps.prepare.frictionless.continueButton': 'Tirar foto',
    'sms.sharedSteps.prepare.frictionless.owner': buttons.owner,
    'sms.tooltip.explainFrictionless': 'A verificação serve para sabermos se essa compra é verdadeira e vinculada a você ou a outra pessoa. Assim, podemos evitar fraudes com o cartão utilizado.',
    'sms.tooltip.explainShare': 'A pessoa que realizou a compra com o cartão de <strong>final {{lastDigits}}</strong> te enviou o pedido de verificação de segurança. Para finalizá-lo, confirme sua identidade através de uma foto do seu rosto.',

    // Help Chat
    'sms.helpChat.header': 'Ajuda',
    'sms.helpChat.title': 'Opa! Em breve isso será possível.',
    'sms.helpChat.text1': 'Ainda estamos trabalhando para que você possa sanar suas dúvidas como desejar.',
    'sms.helpChat.text2': 'Por enquanto, em caso de dúvidas, acesse nosso <link/>.',
    'sms.helpChat.link': 'Portal de Ajuda',
    'sms.helpChat.category1': 'É confiável tirar a foto?',
    'sms.helpChat.category2': 'Não consigo tirar a foto',
    'sms.helpChat.category3': 'Quem é Unico?',
    'sms.helpChat.category4': 'Tenho outra dúvida',

    //Belvo
    'sms.notify.cardValidationBelvo.title': 'Valide seu cartão de crédito',
    'sms.notify.cardValidationBelvo.subtitle': 'Precisamos confirmar a sua titularidade.',
    'sms.notify.cardValidationBelvo.info.text1': 'Essa validação será feita via <strong>Open Finance</strong> pela nossa parceira Belvo.',
    'sms.notify.cardValidationBelvo.info.text2': 'Open Finance é uma maneira de compartilhar informações sobre contas bancárias com outras empresas, com a sua autorização, para criar serviços que ajudam a gerenciar e manter o seu dinheiro e os seus dados seguros.',
    'sms.notify.cardValidationBelvo.button.validateCard': 'Validar cartão',
    'sms.notify.cardValidationBelvo.button.notNow': 'Não quero validar',
    'sms.notify.cardValidationBelvo.pollingText': 'Continue a validação na nova aba.<br />Após finalizar, seguiremos por aqui.',
    'sms.notify.successConnectionBelvo.title': 'Conexão feita com sucesso!',
    'sms.notify.successConnectionBelvo.card': 'Cartão',
    'sms.notify.successConnectionBelvo.withEnd': 'com final',
    'sms.notify.verificationFinishedBelvo.title': 'Verificação finalizada',
    'sms.notify.verificationFinishedBelvo.subtitle': 'Agora a loja irá analisar as informações da compra para definir o status do pedido.',
    'sms.notify.verificationFinishedBelvo.description': 'Para saber o status da compra, acompanhe diretamente pelo site ou pelo aplicativo da loja.',
    'sms.notify.verificationFinishedBelvo.status1': 'Compra realizada',
    'sms.notify.verificationFinishedBelvo.status2': 'Verificação de segurança',
    'sms.notify.verificationFinishedBelvo.status3': 'Enviamos as informações para loja',
    'sms.notify.finishBelvo.finishText': 'Open Finance finalizado.<br />Por favor, volte para a aba anterior.<br />Iremos continuar por lá.',
    'sms.modal.notNow.title': 'Se você não validar, seu pedido pode ser <strong>cancelado</strong>',
    'sms.modal.notNow.text': 'Você tem certeza que deseja cancelar a validação?',
    'sms.modal.notNow.button.skipStep': 'Não quero validar',
    'sms.modal.notNow.button.backToValidation': 'Voltar para validação',
    'sms.modal.errorBelvo.title': 'Tivemos um problema para conectar ao banco',
    'sms.modal.errorBelvo.text': 'Tente novamente para continuar com a validação do cartão.',
    'sms.modal.errorBelvo.button.tryAgain': 'Tentar novamente',

    //Iframe One Page
    'sms.notify.prepareOnePage.title': 'Tire a selfie do titular do cartão',
    'sms.notify.prepareOnePage.titleWithCpf': 'Pagamento em análise',
    'sms.notify.prepareOnePage.subtitleWithCpf': 'O titular do cartão precisa tirar uma selfie para autorizar o pagamento.',
    'sms.notify.prepareOnePage.title.permission': 'Para você continuar é necessário <strong>permitir</strong> o acesso a câmera.',
    'sms.notify.prepareOnePage.title.permissionWithCpf': 'Permita o acesso a câmera',
    'sms.notify.prepareOnePage.button.captureSelfie': 'Tirar Selfie',
    'sms.notify.prepareOnePage.button.captureSelfieOwner': 'Sou o titular, tirar selfie',
    'sms.notify.prepareOnePage.button.share': 'Enviar para titular',
    'sms.notify.prepareOnePage.cpf.label': 'Digite o CPF do titular do cartão',
    'sms.notify.validationFrictionless.checkFinished': 'Verificação concluída',
    'sms.notify.validationFrictionless.checking': 'Verificando informações',
    'sms.newFooter': 'Ao continuar você concorda com os <termUse /> e <termPrivacyPolicy /> da Unico',
    'sms.newFooter.termUse': 'Termos de Uso',
    'sms.newFooter.termPrivacyPolicy': 'Política de Privacidade',
  }
}
