import {
  STEP_CARD_VALIDATION_BELVO,
  STEP_CONFIRM_OWNER_DATA,
  STEP_CONGRATULATIONS_FRICTIONLESS,
  STEP_ERROR, STEP_EXPIRED, STEP_PREPARE,
  STEP_VALIDATION_FRICTIONLESS,
} from "../defaults/steps";

export const TRANSACTION_STEP__EXPIRED = 'expired';
export const TRANSACTION_STEP__OPEN_FINANCE = 'open-finance';
export const TRANSACTION_STEP__CONGRATULATIONS = 'congratulations';
export const TRANSACTION_STEP__FRICTIONLESS = 'frictionless';
export const TRANSACTION_STEP__HOLDER_DEFINITION = 'holder-definition';
export const TRANSACTION_STEP__BIOMETRIC = 'biometric';

export const TRANSACTION_NEXT_STEP__STOP = 'stop';
export const TRANSACTION_NEXT_STEP__ERROR = 'error';
export const TRANSACTION_NEXT_STEP__CONTINUE_BIOMETRIC = 'continue-biometric';
export const TRANSACTION_NEXT_STEP__CONTINUE_FRICTIONLESS = 'continue-frictionless';
export const TRANSACTION_NEXT_STEP__CONTINUE_OPEN_FINANCE = 'continue-open-finance';

export const CURRENT_STEP_TO_STEP = {
  [TRANSACTION_STEP__EXPIRED]: STEP_EXPIRED,
  [TRANSACTION_STEP__OPEN_FINANCE]: STEP_CARD_VALIDATION_BELVO,
  [TRANSACTION_STEP__CONGRATULATIONS]: STEP_CONGRATULATIONS_FRICTIONLESS,
  [TRANSACTION_STEP__FRICTIONLESS]: STEP_VALIDATION_FRICTIONLESS,
  [TRANSACTION_STEP__HOLDER_DEFINITION]: STEP_CONFIRM_OWNER_DATA,
  [TRANSACTION_STEP__BIOMETRIC]: STEP_PREPARE,
};

export const NEXT_STEP_TO_STEP = {
  [TRANSACTION_NEXT_STEP__STOP]: STEP_CONGRATULATIONS_FRICTIONLESS,
  [TRANSACTION_NEXT_STEP__CONTINUE_OPEN_FINANCE]: STEP_CARD_VALIDATION_BELVO,
  [TRANSACTION_NEXT_STEP__CONTINUE_FRICTIONLESS]: STEP_VALIDATION_FRICTIONLESS,
  [TRANSACTION_NEXT_STEP__CONTINUE_BIOMETRIC]: STEP_PREPARE,
  [TRANSACTION_NEXT_STEP__ERROR]: STEP_ERROR,
};
